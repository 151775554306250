import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import NewsCard from '../common/NewsCard';

const News = () => {
  const { magyarEgyetem, dij, bge, helpAll } = useStaticQuery(graphql`
    query {
      magyarEgyetem: file(relativePath: { eq: "news/magyar_egyetem.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 282, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      dij: file(relativePath: { eq: "news/dij.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 282, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      bge: file(relativePath: { eq: "news/bge.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 282, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      helpAll: file(relativePath: { eq: "news/help_all.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 282, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `);

  const content = [
    {
      title: 'Magyar egyetemi ötletek hódíthatják meg a világot',
      body:
        'Vasban, rostban és esszenciális aminosavakban gazdag tücsökliszt, távolról irányítható okos gyógyszeradagoló, jutalomrendszer mobilalkalmazás, iskolák és tanárok számára a diákok hatékony motiválásához, új generációs növénytermesztési rendszer, elsősorban szántóföldi kultúrákhoz, valamint csőhálózat és kút monitorozó robot. Csak néhány abból a 20 projektből, amit a szakértők a legígéretesebb ötletnek ítéltek a HSUP program második tanévének végén. Ezek a magyar egyetemi ötletek hódíthatják meg a világpiacokat, ezekből nőhetnek ki az évtized magyar unikornisai. Az EFOTT fesztivál kísérő rendezvényeként Kápolnásnyéken a Hungarian Startup University Program (HSUP) Demo Day eseményén most bemutatkoznak a projektcsapatok.',
      img: magyarEgyetem,
      linkTo:
        'https://nkfih.gov.hu/hivatalrol/hivatal-hirei/magyar-egyetemi-otletek'
    },
    {
      title:
        'Nemzetközi szinten is felfigyeltek egy fiatal innovátorokat támogató magyar programra',
      body:
        'A fiatal innovátorokat támogató Hungarian Startup University Program (HSUP) kapta idén a „Most Innovative Business Development Project of Europe 2021” díjat. A rangos nemzetközi elismerést a program egyik alapítója, Kölkedi Krisztián, az Express Innovation Agency vezérigazgató-helyettese vehette át a 2021-es Fiatal Vállalkozók Hete keretében.',
      img: dij,
      linkTo:
        'https://startuponline.hu/nemzetkozi-szinten-is-felfigyeltek-egy-fiatal-innovatorokat-tamogato-magyar-programra/'
    },
    {
      title:
        'Először Magyarországon: startup ökoszisztémára fókuszáló adat-felület',
      body:
        'A Hungarian Startup University Programot (HSUP) megvalósító Express Innovation Agency, valamint a Dealroom.co startupokra és tech ökoszisztémákra koncentráló nemzetközi adatszolgáltatóval kötött megállapodás keretében megnyílt a startup ökoszisztéma fókuszú, közös magyarországi platform, amelynek célja, hogy fellendítse a magyar innovációt és vállalkozói szellemet.',
      img: bge,
      linkTo:
        'https://startuponline.hu/eloszor-magyarorszagon-startup-okoszisztemara-fokuszalo-adat-felulet/'
    },
    {
      title:
        'Mindenkinek segítünk, akinek van egy jó ötlete! – interjú Kölkedi Krisztiánnal',
      body:
        'A hazai startup ökoszisztémába való bekerülési lehetőségekről, magáról a Hungarian Startup University Programról (HSUP), az Express Innovation Agency hazai és nemzetközi szerepvállalásáról beszélgetés SMART előadása után Kölkedi Krisztiánnal, az ügynökség stratégiai vezérigazgató-helyettesével és a HSUP egyik alapítójával.',
      img: helpAll,
      linkTo:
        'https://ivsz.hu/hirek/mindenkinek-segitunk-akinek-van-egy-jo-otlete-interju-kolkedi-krisztiannal/'
    }
  ];

  return (
    <section className="bg-snow">
      <div className="container">
        <div className="flex flex-col p-4 pb-20 lg:justify-center">
          <h2 className="text-center py-8">Hírek</h2>
          {content.map(({ title, body, img, linkTo }) => (
            <NewsCard
              key={title}
              title={title}
              body={body}
              button
              buttonText="elolvasom"
              linkTo={linkTo}
              img={img}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default News;
