import React from 'react';
import Img from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';
import { useStaticQuery, graphql, Link } from 'gatsby';

const Introduction = () => {
  const {
    learn,
    blog,
    appStoreBadge,
    playStoreBadge,
    bgInstructions
  } = useStaticQuery(graphql`
    query {
      bgInstructions: file(relativePath: { eq: "bg_instructions.png" }) {
        childImageSharp {
          fluid(maxWidth: 1191, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      learn: file(relativePath: { eq: "illu_learn.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      blog: file(relativePath: { eq: "illu_blog.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }

      playStoreBadge: file(relativePath: { eq: "badges/playstore.png" }) {
        childImageSharp {
          fixed(width: 270, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
      appStoreBadge: file(relativePath: { eq: "badges/appstore.png" }) {
        childImageSharp {
          fixed(width: 240, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
    }
  `);

  const mobileAppPlayStoreURL =
    'https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=hu.hsup.student';
  const mobileAppAppStoreURL =
    'https://apps.apple.com/hu/app/hsup/id1583251930?itsct=apps_box_link&itscg=30200';

  const backgroundFluidImageStack = [
    `linear-gradient(180deg, #F5F6F9 90.85%, rgba(222, 224, 233, 0.903371) 111.64%, rgba(194, 196, 213, 0.780596) 111.64%, rgba(14, 22, 85, 0.01) 111.64%)`,
    bgInstructions.childImageSharp.fluid
  ].reverse();

  const smallerH2 = {
    fontSize: '1.5rem'
  };

  return (
    <BackgroundImage
      tag="section"
      preserveStackingContext
      fluid={backgroundFluidImageStack}
      style={{
        backgroundSize: 'unset',
        backgroundPosition: '0% calc(100% + 306px)', // Full height + half height of the image
        backgroundRepeat: 'no-repeat'
      }}
      id="intro"
    >
      <div className="container">
        <div
          className="flex justify-center"
          style={{ transform: 'translateY(-25%)' }}
        >
          <div className="max-w-5xl m-4 video-container">
            <iframe
              src="https://www.youtube.com/embed/Ae_duthFBlw"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullscreen
            />
          </div>
        </div>

        <div className="text-center pb-4">
          <h1 className="pb-8 mx-auto lg:w-3/5">
            Üdvözlünk az első, online, egyetemi startup képzés weboldalán!
          </h1>
        </div>

        <div className="lg:flex lg:flex-row-reverse lg:justify-center lg:items-center">
          <div className="p-4 lg:w-6/12">
            <div className="intro-card">
              <h2 className="mb-6" style={smallerH2}>
                A HSUP program célja
              </h2>
              <div className="mb-6">
                A HSUP célja a hazai egyetemisták megismertetése az innováció
                világával, a modern vállalkozói ismeretekkel és különösen a
                startupok működésével, mindezt egy új, közös oktatási platformon
                keresztül.
              </div>
              <div>
                <Link
                  to="#intro"
                  className="btn btn-hsup mb-4 lg:mb-0 mr-4 lg:mr-0"
                  style={{ paddingTop: '14px' }}
                >
                  Megnézem a programbemutatót
                </Link>
              </div>
            </div>
          </div>
          <div className="lg:w-6/12">
            <Img
              alt="A HSUP célja a hazai egyetemisták megismertetése az innováció világával, a modern vállalkozói ismeretekkel, és különösen a startupok folyamataival, mindezt egy közös, új oktatási platformon keresztül."
              fluid={learn.childImageSharp.fluid}
              style={{ maxWidth: 500 }}
              className="flex items-center justify-center m-auto"
            />
          </div>
        </div>

        <div className="lg:flex lg:flex-row lg:justify-center lg:items-center">
          <div className="p-4 lg:w-6/12">
            <div className="intro-card">
              <h2 className="mb-6" style={smallerH2}>
                Interaktív, játékos tananyagok segítségével tanulhatsz
              </h2>
              <div className="mb-6">
                Ha korábban még nem volt alkalmad elmerülni a piac, a pénz és az
                innováció világában, akkor sem kell megijedned. A Hungarian
                Startup University Programban interaktív, játékos tananyagok,
                személyre szabott tartalmak segítségével a saját tempódban
                tanulhatsz, és mi is mindvégig melletted leszünk. A magyar
                startup világ folyamatosan alakul, fejlődik, és ebben rád is
                számítunk. Biztosak vagyunk benne, hogy együtt még messzebbre
                juthatunk.
              </div>
              <div>
                <Link
                  to="/folyamat"
                  className="btn btn-hsup mb-4 lg:mb-0 mr-4 lg:mr-0"
                  style={{ paddingTop: '14px' }}
                >
                  A teljes hsup folyamat
                </Link>
              </div>
            </div>
          </div>
          <div className="lg:w-6/12">
            <Img
              alt="A HSUP-ban interaktív, játékos tananyagokon, a saját tempódban, számodra személyre szabott tartalmakon keresztül haladhatsz, és mi is mindvégig veled vagyunk."
              fluid={blog.childImageSharp.fluid}
              style={{ maxWidth: 500 }}
              className="flex items-center justify-center m-auto"
            />
          </div>
        </div>

        <div className="p-4 md:mt-24 pb-24">
          <div className="intro-card p-12">
            <h2 className="mb-6" style={smallerH2}>
              Tanulj akár alkalmazáson keresztül is!
            </h2>
            <div className="mb-6">
              Ahhoz, hogy bármikor könnyedén hozzáférj a tananyaghoz, töltsd le
              a HSUP applikációt az{' '}
              <a
                href={mobileAppAppStoreURL}
                className="text-secondary underline"
                target="_blank"
                rel="noreferrer"
              >
                App Store
              </a>
              -on vagy a{' '}
              <a
                href={mobileAppPlayStoreURL}
                className="text-secondary underline"
                target="_blank"
                rel="noreferrer"
              >
                Google PlayStore
              </a>
              -on keresztül és engedélyezd az értesítéseket, hogy mindig képben
              legyél az aktuális tudnivalókkal.
            </div>
            <div>
              <Link to={mobileAppPlayStoreURL} className="pr-4" target="_blank">
                <Img
                  alt="Google PlayStore letöltés"
                  fixed={playStoreBadge.childImageSharp.fixed}
                  style={{ width: 135, height: 40 }}
                  imgStyle={{ objectFit: 'contain' }}
                />
              </Link>
              <Link to={mobileAppAppStoreURL} target="_blank">
                <Img
                  alt="App Store letöltés"
                  fixed={appStoreBadge.childImageSharp.fixed}
                  style={{ width: 120, height: 40 }}
                  imgStyle={{ objectFit: 'contain' }}
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </BackgroundImage>
  );
};

export default Introduction;
