import React from 'react';
import { FaqItem } from '../common';

const Faq = () => {
  const [activeAnswerIndex, setActiveAnswerIndex] = React.useState(1);

  const onClick = index => {
    if (index === activeAnswerIndex) {
      setActiveAnswerIndex(null);
    } else {
      setActiveAnswerIndex(index);
    }
  };

  return (
    <section className="bg-snow" id="faq">
      <div className="container">
        <div className="flex flex-col p-4 pb-20 lg:justify-center">
          <h2 className="text-center py-8">Gyakori kérdések</h2>

          <FaqItem
            question="Pontosan mit fogok tanulni?"
            index={1}
            handleClick={() => onClick(1)}
            activeAnswerIndex={activeAnswerIndex}
          >
            <p className="text-gray-700 my-8 text-justify">
              A HSUP összesen 12 modulból áll. Ebből hetet az első félévben, és
              ötöt a másodikban sajátíthatsz el.
            </p>

            <div className="flex flex-col md:flex-row">
              <div className="p-2">
                <p className="font-bold text-primary">I. félév</p>
                <ol className="list-decimal px-6 md:px-12 text-bluegrey">
                  <li>Bevezetés: Mi az innováció?</li>
                  <li>Ötletvalidáció</li>
                  <li>Üzleti és pénzügyi tervezés</li>
                  <li>Piacra lépés</li>
                  <li>Szellemi tulajdon</li>
                  <li>A befektetők</li>
                  <li>A pitch</li>
                </ol>
              </div>
              <div className="p-2">
                <p className="font-bold text-primary">II. félév</p>
                <ol className="list-decimal px-6 md:px-12 text-bluegrey">
                  <li>A csapat</li>
                  <li>Piac és piacra lépési stratégia</li>
                  <li>BMC és prototipizálás</li>
                  <li>Üzleti és pénzügyi tervezés II.</li>
                  <li>Pitch – az ötlet bemutatása</li>
                </ol>
              </div>
            </div>
          </FaqItem>
          <FaqItem
            question="A tananyag felépítése és tartalma"
            index={2}
            handleClick={() => onClick(2)}
            activeAnswerIndex={activeAnswerIndex}
          >
            <p className="text-gray-700 my-8 text-justify">
              A fejezetek moduláris szerkezetűek, a szöveges és képes elemek
              mellett, esettanulmányok, interaktív, a témakörhöz kapcsolódó
              tesztek, beágyazott podcastok, online videók és egyéb tartalmak is
              megjelennek az anyagban. A közösségi tanulás során a hallgatók
              maguk is kiegészítő segédanyagokat gyűjthetnek és oszthatnak meg.
              A tananyagok egymásra épülnek, így azokat csak meghatározott
              sorrendben lehet teljesíteni. A mindenkire érvényes teljes
              tananyag bónusz tartalmakkal egészül ki a személyiségtípusoknak
              megfelelően. Minden modul egy összefoglaló videóval és egy
              teszttel zárul, ahol a hallgatók felmérhetik elsajátított
              tudásukat.
            </p>
          </FaqItem>
          <FaqItem
            question="Személyre szabott tanulás"
            index={3}
            handleClick={() => onClick(3)}
            activeAnswerIndex={activeAnswerIndex}
          >
            <p className="text-gray-700 my-8 text-justify">
              A program bejelentkezés után egy személyiségi teszt kitöltésével
              kezdődik, amely segítségével javaslatot teszünk arra, hogy a
              hallgató milyen szerepet tölthetne be legsikeresebben egy
              csapatban. A felmérést a “16 personalities” nevű kiterjesztett
              személyiségtesztre alapozzuk, mellyel a legpontosabb betekintést
              nyerhetünk az egyéni kompetenciákról. A hallgatót ez alapján: CEO,
              CTO, Projekt Menedzser és Kreatív (Designer) útvonalakra
              irányíthatjuk a négy fő irányra, amely nélkül nehéz sikeres
              startupot indítani.
            </p>
            <p className="text-gray-700 my-8 text-justify">
              A tananyag személyiségtípusonként egyénre szabott útvonalakat
              ajánl, de emellett az összes csapattagnak egységes tudást ad. Így
              mindenki megtanulja a HSUP teljes tananyagát, de saját útvonalon,
              saját érdeklődésének megfelelően extra tartalmakkal találkozhat,
              és így fejlődhet.
            </p>
          </FaqItem>
          <FaqItem
            question="Követelmények az első és második félévben"
            index={5}
            handleClick={() => onClick(5)}
            activeAnswerIndex={activeAnswerIndex}
          >
            <p className="text-gray-700 my-8 text-justify">
              A HSUP nem egy hagyományos egyetemi kurzus, így a tesztektől sem
              kell megijedni, számunkra a fejlődés a legfontosabb. Ahhoz, hogy a
              hallgatók előrehaladásukról visszajelzést kaphassanak, minden
              modul végén egy modulzáró teszt található. Ezek teljesítésén túl
              az első félévben a hallgatóknak egy onepager formájában kell
              bemutatniuk innovatív ötletüket. A második félévben hallgatóknak
              lehetősége van projekt csapatokban dolgozni egy-egy kiválasztott
              innovatív vállalkozási ötlet megvalósításán, illetve egyénileg is
              folytathatják a kurzust, és teljesíthetik a modulokat. A második
              félévben a csapatban dolgozó hallgatók ösztöndíjban részesülnek.
            </p>
          </FaqItem>
          <FaqItem
            question="Csak akkor jelentkezhetek ha már van ötletem?"
            index={6}
            handleClick={() => onClick(6)}
            activeAnswerIndex={activeAnswerIndex}
          >
            <p className="text-gray-700 mt-8 mb-4 text-justify">
              Nem! A Hungarian Startup University Program egyik legfontosabb
              célkitűzése, hogy felkeltse az érdeklődést az innováció és a
              startupok világa iránt, így ötleted a kurzus elvégzése alatt is
              születhet. Illetve a második félévben, ha esetleg a te ötleted nem
              kerül kiválasztásra, abban az esetben más HSUP hallgatók ötletéhez
              is csatlakozhatsz csapattagként.
            </p>
          </FaqItem>
          <FaqItem
            question="A második félévet is muszáj elvégeznem?"
            index={7}
            handleClick={() => onClick(7)}
            activeAnswerIndex={activeAnswerIndex}
          >
            <p className="text-gray-700 mt-8 mb-4 text-justify">
              Nem! A második féléves csapatmunka a HSUP egyik legizgalmasabb
              része, és a tananyag is rengeteg további hasznos ismeretet
              tartalmaz, de ha úgy érzed, hogy bemelegítésként csak az első
              félévhez csatlakoznál, semmi gond, ezt is megteheted.
            </p>
          </FaqItem>
          <FaqItem
            question="A második félévben csak saját szaktársaimmal alkothatok egy csapatot?"
            index={8}
            handleClick={() => onClick(8)}
            activeAnswerIndex={activeAnswerIndex}
          >
            <p className="text-gray-700 mt-8 mb-4 text-justify">
              Nem! A HSUP egyetemtől, kartól és szaktól független, így bárki
              elvégezheti. A HSUP oktatási platformon keresztül is kereshetsz
              csapatot, de az oktatási intézményed is segíthet neked abban, hogy
              a második félévben minél változatosabb csapattagokkal, akár más
              egyetem hallgatóival is együtt dolgozhass.
            </p>
          </FaqItem>
          <FaqItem
            question="Hogyan kapok jegyet?"
            index={9}
            handleClick={() => onClick(9)}
            activeAnswerIndex={activeAnswerIndex}
          >
            <p className="text-gray-700 mt-8 mb-4 text-justify">
              A HSUP tananyaga összesen 7 + 5 modulból áll. A hallgató minden
              modul végén egy modulzáró tesztet talál. Egy teszt 10 kérdésből
              áll, sikeres válasz 1 pontot, sikertelen válasz 0 pontot ér. A
              végső ajánlott érdemjegyet a hallgató összes elért pontszáma
              határozza meg: 1: 0-60, 2: 61-70, 3: 71-80, 4: 81-90, 5: 91-100
            </p>
          </FaqItem>
        </div>
      </div>
    </section>
  );
};

export default Faq;
