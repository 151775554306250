import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Card } from '../common';

const Learn = () => {
  const { character, unique, exp } = useStaticQuery(graphql`
    query {
      character: file(relativePath: { eq: "illu_character.png" }) {
        childImageSharp {
          fluid(maxWidth: 588, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      unique: file(relativePath: { eq: "illu_unique.png" }) {
        childImageSharp {
          fluid(maxWidth: 544, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      exp: file(relativePath: { eq: "illu_exp.png" }) {
        childImageSharp {
          fluid(maxWidth: 604, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `);

  const content = [
    {
      title: 'Karakterek',
      body:
        'A vállalkozás a felnőttek társasjátéka. Az, hogy milyen bábuval játszol, hogy egy vállalkozáson belül milyen szerepet tölthetsz be, több különböző tényezőtől függ. A HSUP-ban is a pálca választja a varázslót! A program egy szereposztó teszt kitöltésével kezdődik, amelynek alapján javaslatot teszünk arra, hogy a startup világ 4 legfontosabb alapítói szerepe (CEO - ügyvezető, CTO – technológiai vezető, projektmenedzser és designer) közül melyikben végezd el a képzést.',
      img: character
    },
    {
      title: 'Egyedi tartalmak',
      body:
        'A HSUP bónusztananyagai valódi kincsesbányát jelentenek. A teljes tananyag mellett a szerepednek megfelelően különböző személyre szabott tartalmakkal bővítheted tudásodat. Ha már unod a könyveket és jegyzeteket, mi a legjobb videókkal, podcastokkal és esettanulmányokkal készülünk neked.',
      img: unique
    },
    {
      title: 'Társasjáték-élmény',
      body:
        'A HSUP-ban a tanulás során tapasztalatpontokat gyűjtesz, így követheted az előrehaladásodat. Az egyéni fejlődés mellett a közösségi szemléletet is jutalmazzuk, így az együttműködésben aktívan részt vevők közösségi pontokat kapnak. A játékos környezetben a tapasztalatpontok alapján kialakul egy rangsor, amelyben követheted saját, de később egyetemed teljesítményét is. Így nemcsak egymással versenyeztek, hanem az egyetemetekért is küzdötök.',
      img: exp
    }
  ];

  return (
    <section className="bg-snow">
      <div className="container">
        <div className="flex flex-col p-4 pb-20 lg:justify-center">
          <h2 className="text-center py-8">Tanulj velünk kicsit másképp!</h2>
          {content.map(({ title, body, img }) => (
            <Card key={title} title={title} body={body} img={img} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Learn;
