import React from 'react';

const Where = () => {
  return (
    <section className="bg-primary" id="where">
      <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center justify-center">
        <div className="flex md:w-1/2 flex-col items-center justify-center text-center">
          <h1 className="py-2 text-header font-bold leading-snug text-snow">
            Hol tanulhatom?
          </h1>
          <h3 className="py-2 text-lg font-medium leading-snug text-white">
            A leginnovatívabb magyar egyetemeken!
          </h3>
        </div>
      </div>
    </section>
  );
};

export default Where;
