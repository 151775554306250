import React from 'react';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';

const Why = () => {
  const { why } = useStaticQuery(graphql`
    query {
      why: file(relativePath: { eq: "illu_why.png" }) {
        childImageSharp {
          fluid(maxWidth: 1040, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `);

  return (
    <section>
      <div className="container">
        <div className="lg:flex lg:flex-row lg:justify-center lg:items-center py-16">
          <div className="p-4 lg:w-5/12">
            <h2 className="text-center lg:text-left py-8">Miért a HSUP?</h2>
            <h3 className="text-center lg:text-left pb-10">
              Biztosak vagyunk benne, hogy mindenkiben van kreativitás, és képes
              a saját érdeklődési területén innovációra.
            </h3>
            <p className="text-bluegrey">
              Lehetsz mérnök, közgazdász, bölcsész, orvos vagy ügyvéd, nap mint
              nap megújítasz valamit, csak talán nem tudatosan. Meg kell
              tanulnod, hogyan tudod megvalósítani az ötleteidet!
            </p>
          </div>
          <div className="lg:w-5/12">
            <Img
              alt="Biztosan tudjuk, hogy mindenki kreatív, és képes a saját érdeklődési területén innoválni."
              fluid={why.childImageSharp.fluid}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Why;
