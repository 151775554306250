import React from 'react';

const WhatsIn = () => {
  return (
    <section>
      <div className="container">
        <div className="lg:flex lg:flex-row lg:justify-center lg:items-center py-16">
          <div className="p-4 lg:w-5/12">
            <h2 className="text-center lg:text-left py-8">Mit kaphatok?</h2>
            <h3 className="text-center lg:text-left pb-8">
              A Hungarian Startup University Program (HSUP) Magyarország első
              felsőoktatási szintű startup kurzusa. 
            </h3>
            <p className="text-bluegrey">
              A HSUP egy két féléves e-learning tárgy, amelynek I. félévében
              az innovatív gondolkodásmód és a startup világ megismerése kerül
              a fókuszba, míg a II. félévben egy vállalkozás felépítésével
              kapcsolatos gyakorlati tudást sajátíthatsz el.
            </p>
          </div>
          <div className="lg:w-5/12 flex justify-center">
            <div className="m-4 video-container">
            <iframe
              src="https://www.youtube.com/embed/TdslMQ_0hY0"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullscreen
            />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhatsIn;
