import React from 'react';
import PropTypes from 'prop-types';
import { scroller, Element } from 'react-scroll';
import { Layout } from '../components/common';
import {
  Hero,
  Introduction,
  WhatsIn,
  WhatsMore,
  Why,
  Where,
  Learn,
  Partners,
  Testimonial,
  Contact,
  PartnerOffers
} from '../components/home';
import { Faq, News } from '../components/shared';

const IndexPage = ({ location }) => {
  const { hash } = location;

  React.useLayoutEffect(() => {
    if (hash) {
      setTimeout(() => {
        const target = hash.split('#')[1];
        scroller.scrollTo(target, {
          duration: 700,
          delay: 0,
          smooth: 'easeInOutQuart',
          offset: -100
        });
      }, 1);
    }
  }, [hash]);

  return (
    <Layout
      seo={{
        title: 'Kezdőlap',
        lang: 'hu',
        description:
          'Szeptemberben startol a Hungarian Startup University Program'
      }}
      baseRoute="/"
    >
      <Hero />
      <Introduction />
      <WhatsIn />
      <WhatsMore />
      <Testimonial />
      <PartnerOffers />
      <Why />
      <Learn />
      <Element name="where">
        <Where />
      </Element>
      <Partners />
      <News />
      <Element name="contact">
        <Contact />
      </Element>
      <Element name="faq">
        <Faq />
      </Element>
    </Layout>
  );
};

IndexPage.propTypes = {
  location: PropTypes.shape().isRequired
};

export default IndexPage;
