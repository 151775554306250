import React from 'react';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';

const Hero = () => {
  const { growth, hsup } = useStaticQuery(graphql`
    query {
      growth: file(relativePath: { eq: "bg_growth.png" }) {
        childImageSharp {
          fluid(maxWidth: 1300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      hsup: file(relativePath: { eq: "bg_hsup.png" }) {
        childImageSharp {
          fluid(maxWidth: 201, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `);

  return (
    <section className="p-1 relative">
      <div
        className="lg:pb-24 md:pb-12"
        style={{
          background:
            'linear-gradient(180deg, rgba(245,246,249,1) 0%, rgba(255,255,255,1) 100%)'
        }}
      >
        <div className="flex my-10 md:flex-row flex-col items-center xl:container">
          <div className="hidden md:block md:w-1/2">
            <Img
              className="mr-auto"
              alt="Emelkedj ki a tömegből!"
              fluid={growth.childImageSharp.fluid}
              style={{ maxWidth: 619 }}
            />
          </div>
          <div className="md:w-1/2 xl:w-1/3 py-12 md:py-24 flex flex-col md:text-left text-center mt-10 mb:mt-0">
            <h1>Emelkedj ki a tömegből!</h1>
            <h3 className="py-8">
              Szeptemberben startol a Hungarian Startup University Program
            </h3>
            <div>
              <a
                href="/app"
                target="_blank"
                className="btn btn-hsup mb-4 lg:mb-0 mr-4 lg:mr-0"
                style={{ paddingTop: '14px' }}
              >
                Bejelentkezés
              </a>
            </div>
          </div>
          <div className="hidden xl:block w-1/5 pt-56 pl-12">
            <Img
              className="ml-auto"
              alt="Szemptemberben startol a Hungarian Startup University Program"
              fluid={hsup.childImageSharp.fluid}
              style={{ maxWidth: 201 }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
