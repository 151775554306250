import React from 'react';

import { useStaticQuery, graphql } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import useWindowSize from '../../hooks/useWindowSize';

const backgroundStyle = {
  background:
    'linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.2) 100%), #80BC00'
};

const headlineStyle = {
  fontSize: 34,
  letterSpacing: 9.5,
  textTransform: 'uppercase',
  color: '#0E1655'
};

const leadStyle = {
  fontSize: 24,
  color: '#FFFFFF'
};

const btnStyle = {
  zIndex: 1 // Place button above bubbles on mobile view
};

export default function PartnerOffers() {
  const { credit, logo } = useStaticQuery(graphql`
    query {
      credit: file(relativePath: { eq: "illu_credit_blue.png" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      logo: file(relativePath: { eq: "hsup_logo_title.png" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `);

  return (
    <section>
      <div
        // relative and overflow classes are required for bubbles
        className="relative overflow-hidden"
        style={backgroundStyle}
      >
        <div
          className="container flex lg:w-full py-16 px-8 flex-wrap md:flex-row flex-col justify-center items-center"
        >
          <div className="w-full md:w-6/12">
            <GatsbyImage
              alt="logo with title"
              fluid={logo.childImageSharp.fluid}
              className="w-64 flex justify-center md:justify-start m-auto md:m-0"
            />
            <h3 style={headlineStyle}>Partnerek</h3>
            <h3 style={leadStyle} className="mt-8">
              A Hungarian Startup University Programhoz olyan szervezetek és
              vállalkozások csatlakoznak partnerként, akik különböző
              felajánlásokkal szeretnék segíteni ötleteitek megvalósítását. Ezek a
              felajánlások csak a HSUP hallgatóinak szólnak, ezért, ha részt
              veszel a programban, böngéssz az ajánlatok között és találd meg a
              csapatodnak megfelelőt.
            </h3>
            <div className="w-full flex justify-center md:justify-start mt-8">
              <a href="/partner" target="_blank" style={btnStyle} className="btn btn-primary">
                partnerek megtekintése
              </a>
            </div>
          </div>
          <div className="mt-16 lg:mt-0 w-full md:w-6/12 flex justify-center md:justify-end">
            <div className="rounded md:mt-16">
              <GatsbyImage
                alt="credit"
                fluid={credit.childImageSharp.fluid}
                className="flex rounded items-center justify-center m-auto w-72 md:w-96"
              />
            </div>
          </div>
        </div>
        <Bubbles />
      </div>
    </section>
  );
}

function Bubbles() {
  const size = useWindowSize();

  const bubbleStyle = {
    position: 'absolute',
    borderRadius: '50%',
    opacity: 0.2
  };

  const leftBubbleSize = 200 + size.width / 6;
  const rightBubbleSize = 300 + size.width / 3;

  const leftBubbleStyle = {
    ...bubbleStyle,
    backgroundColor: '#F0F7E2',
    height: leftBubbleSize,
    width: leftBubbleSize,
    top: -150,
    left: -100
  };

  const rightBubbleStyle = {
    ...bubbleStyle,
    backgroundImage: 'linear-gradient(#F0F7E2, #80BC00)',
    height: rightBubbleSize,
    width: rightBubbleSize,
    right: -100,
    top: 100
  };

  return (
    <>
      <div className="md:block -mb-16" style={leftBubbleStyle} />
      <div className="md:block mt-96 -mb-16 md:mt-0" style={rightBubbleStyle} />
    </>
  );
}
